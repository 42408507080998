import React from "react";
import './index.css';

const About = () => {
  return (
    <div id='about' className='About-root'>
      <h1 className='About-title'>About Us</h1>
      <div className='About-policy'>
        <p className='About-text'>Makayla Burks is the owner of Keyed in Music Studio. Her love for music and the arts started at the young age of 7 when she began taking
        private piano lessons, and she has continued to perfect her craft for the last 20 years. Makayla received a Bachelors Degree in Education and worked in school
        settings for many years, incorporating learning strategies into her teaching to best meet the needs of all her different students. Although she loved teaching in
        a school, she wanted to combine her passions of music and education together to create the unique learning environment that is Keyed in Music Studio.</p>
      </div>
    </div>
  );
};

export default About;
