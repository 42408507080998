import React from "react";
import './Home.css';

const Home = () => {
  return (
    <div id='home' className='Home-root'>
      <div className='Home-content'>
        <h1 className='Home-title'>Keyed In Music Studio</h1>
        <h5 className='Home-slogan'>In home piano lessons</h5>
        <div className='Home-buttons'>
          <a href='#enroll'><button>ENROLL</button></a>
          <a href='#contact'><button>CONTACT</button></a>
        </div>
      </div>
    </div>
  );
};

export default Home;
