// Vendor
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

// Internal
import { SERVICE_ID, CONTACT_TEMPLATE_ID, PUBLIC_KEY } from '../../emailKeys';

// Styles
import './index.css';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(SERVICE_ID, CONTACT_TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        alert('Message Sent, We will get back to you shortly', result.text);
      },
      (error) => {
      alert('An error occurred, Please try again', error.text);
      });
  };

  // const resetForm = () => {
  //   setEmail('');
  //   setName('');
  //   setMessage('');
  // };

  return (
    <div id='contact' className='Contact-root'>
      <h1 className='Contact-title'>Contact</h1>
      <p className='Contact-subtitle'>Please feel free to contact us with any questions or inquiries you may have. We are always happy to help!</p>
      <form className='Contact-form' id='Contact-form' onSubmit={handleSubmit.bind(this)} method='POST'>
        <div className='Contact-formGroup'>
          <label htmlFor='name'>Name</label>
          <input name='name' type='text' className='form-control' id='name' value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='Contact-formGroup'>
          <label htmlFor='exampleInputEmail1'>Email address</label>
          <input name='email' type='email' className='form-control' id='email' aria-describedby='emailHelp' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='Contact-formGroup'>
          <label htmlFor='message'>Message</label>
          <textarea name='message' className='form-control' rows='5' id='message' value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
        <button type='submit' className='Contact-btn'>Submit</button>
      </form>
    </div>
  );
};

export default Contact;
