import React from "react";
import './index.css';

const Calendar = () => {
  return (
    <div id='calendar' className='Calendar-root'>
      <h1 className='Calendar-title'>Calendar</h1>
      <div className='Calendar-calendar'></div>
      <p className='Calendar-date'>January 8th – Start of Spring Semester</p>
      <p className='Calendar-date'>April 1st – 5th – Spring Break</p>
      <p className='Calendar-date'>May 6th – 17th – Summer Enrollment</p>
      <p className='Calendar-date'>May 24th – End of Spring Semester</p>
      <p className='Calendar-date'>May 27th – Start of Summer Semester</p>
      <p className='Calendar-date'>July 1st – 5th – Summer Break</p>
      <p className='Calendar-date'>July 29th – August 9th – Fall Enrollment</p>
      <p className='Calendar-date'>August 16th – End of Summer Semester</p>
      <p className='Calendar-date'>August 19th – Start of Fall Semester</p>
      <p className='Calendar-date'>October 16th – 22nd – Fall Break</p>
      <p className='Calendar-date'>November 25th – 29th – Thanksgiving Break</p>
      <p className='Calendar-date'>December 2nd – 13th – Spring Enrollment</p>
      <p className='Calendar-date'>December 20th – End of Fall Semester</p>
      <p className='Calendar-date'>December 23rd – January 3rd – Winter Break</p>
    </div>
  );
};

export default Calendar;
