import About from './About';
import Calendar from './Calendar';
import Contact from './Contact';
import Enroll from './Enroll';
import Home from './Home';
import Policies from './Policies';
import Tuition from './Tuition';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <a href='#home'><div className='App-logo'></div></a>
        <ul className='App-navigation'>
          <li><a className='App-a' href='#enroll'>ENROLL</a></li>
          <li><a className='App-a' href='#about'>ABOUT</a></li>
          <li><a className='App-a' href='#policies'>POLICIES</a></li>
          <li><a className='App-a' href='#tuition'>TUITION</a></li>
          <li><a className='App-a' href='#calendar'>CALENDAR</a></li>
          <li><a className='App-a' href='#contact'>CONTACT</a></li>
        </ul>
      </header>
      <div className='pageContent'>
        <Home />
        <Enroll />
        <About />
        <div className='testblock'>
          <div className='testimage'></div>
        </div>
        <Policies />
        <div className='testblock2'>
          <div className='testimage2'></div>
        </div>
        <Tuition />
        <Calendar />
        <Contact />
      </div>
    </div>
  );
}

export default App;
