import React from "react";
import './Policies.css';

const Policies = () => {
  return (
    <div id='policies' className='Policies-root'>
      <h1 className='Policies-title'>Policies</h1>
      <div className='Policies-policy'>
        <h3 className='Policies-sub-title'>Makeup Lessons</h3>
        <p className='Policies-text'>There will be <b>NO refunds</b> for missed, forgotten or cancelled lessons. <b>Your tuition reserves your time slot, not the lesson itself.</b> In the rare event that the teacher must cancel a lesson, you will be refunded the cost of one lesson. <b>Exceptions can be made for big/unexpected life events.</b></p>
      </div>
      <div className='Policies-policy'>
        <h3 className='Policies-sub-title'>Sickness</h3>
        <p className='Policies-text'>Sickness: We know getting sick can be inevitable, <b>if the student is sick, please let us know so we can cancel to prevent germs from spreading.</b> We do offer a virtual lesson via Zoom or FaceTime that will be conducted during your same lesson day/time (if the student is feeling well enough).</p>
      </div>
      <div className='Policies-policy'>
      <h3 className='Policies-sub-title'>Practicing</h3>
        <p className='Policies-text'>Regular practice is incredibly important to learn how to play proficiently. Practice recommendations may differ based on students' level and ability, but quality practicing is better than quantity. Having a daily routine for practice helps build those strong habits and helps reinforce what we address during lessons.</p>
      </div>
      <div className='Policies-policy'>
        <p className='Policies-text'><b>PLEASE NOTE: We REQUIRE that a parent, guardian, or trusted adult above the age of 18 be in the home during the entirety of the lesson.</b></p>
      </div>
    </div>
  );
};

export default Policies;
