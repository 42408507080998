// Vendor
import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

// Internal
import { SERVICE_ID, ENROLL_TEMPLATE_ID, PUBLIC_KEY } from '../../emailKeys';

// Styles
import './index.css';

// Constants
const ENROLLMENT_TEXT = 'NEW STUDENTS. Fill out the enrollment form below and we will get back to you to set up your first lesson.';
const REENROLLMENT_TEXT = 'EXISTING STUDENTS. Fill out the re-enrollment form below and we will get back to you to set up your continued lessons.';

const Enroll = () => {
  const [reEnrollment, setReEnrollment] = useState(false);

  const [parentName, setParentName] = useState('');
  const [studentName, setStudentName] = useState('');
  const [studentAge, setStudentAge] = useState('');
  const [studentBirthday, setStudentBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [experience, setExperience] = useState('');
  const [level, setLevel] = useState('');
  const [lastlesson, setLastLesson] = useState('');
  const [address, setAddress] = useState('');
  const [day1, setDay1] = useState('');
  const [time1, setTime1] = useState('');
  const [day2, setDay2] = useState('');
  const [time2, setTime2] = useState('');
  const [day3, setDay3] = useState('');
  const [time3, setTime3] = useState('');
  const [extra, setExtra] = useState('');
  const [goals, setGoals] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(SERVICE_ID, ENROLL_TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        alert('Message Sent, We will get back to you shortly', result.text);
      },
      (error) => {
      alert('An error occurred, Please try again', error.text);
      });
  };

  const handleFormToggle = () => {
    if (reEnrollment) setReEnrollment(false);
    else setReEnrollment(true);
  };

  // const resetForm = () => {
  //   setEmail('');
  //   setName('');
  //   setMessage('');
  // };

  console.log(parentName);
  console.log(studentName);
  console.log(experience);
  console.log(level);
  console.log(lastlesson);
  console.log(studentAge);

  useEffect(() => {
    if (experience === 'none') {
      setLevel('');
      setLastLesson('');
    }
  }, [experience]);

  useEffect(() => {
    if (reEnrollment) {
      setStudentAge('');
      setStudentBirthday('');
      setPhone('');
      setEmail('');
      setExperience('');
      setLevel('');
      setLastLesson('');
      setAddress('');
      setExtra('');
      setGoals('');
    }
  }, [reEnrollment]);

  return (
    <div id='enroll' className='Enroll-root'>
      <h1 className='Enroll-title'>{reEnrollment ? 'Re-e' : 'E'}nroll</h1>
      <p className='Enroll-subtitle'>
        {reEnrollment ? REENROLLMENT_TEXT : ENROLLMENT_TEXT}
        <br /><br />Looking for {reEnrollment ? '' : 're-'}enrollment? <b style={{ cursor: 'pointer' }} onClick={handleFormToggle}>Click here!</b>
      </p>
      <form className='Enroll-form' id='contact-form' onSubmit={(e) => handleSubmit(e)} method='POST'>
        <div className='Enroll-formGroup'>
          <label htmlFor='parentName'>Parent Name</label>
          <input name='parentName' type='text' className='form-control' id='parentName' value={parentName} onChange={(e) => setParentName(e.target.value)} />
        </div>
        <div className='Enroll-formGroup'>
          <label htmlFor='studentName'>Student Name(s)</label>
          <input name='studentName' type='text' className='form-control' id='studentName' aria-describedby='emailHelp' value={studentName} onChange={(e) => setStudentName(e.target.value)} />
        </div>
        {
          !reEnrollment && (
            <>
              <div className='Enroll-formGroup'>
                <label htmlFor='studentAge'>Student Age(s)</label>
                <input name='studentAge' type='text' className='form-control' id='studentAge' aria-describedby='emailHelp' value={studentAge} onChange={(e) => setStudentAge(e.target.value)} />
              </div>
              <div className='Enroll-formGroup'>
                <label htmlFor='studentBirthday'>Student Birthday(s)</label>
                <input name='studentBirthday' type='text' className='form-control' id='studentBirthday' aria-describedby='emailHelp' value={studentBirthday} onChange={(e) => setStudentBirthday(e.target.value)} />
              </div>
              <div className='Enroll-formGroup'>
                <label htmlFor='phone'>Contact Phone #</label>
                <input name='phone' type='text' className='form-control' id='phone' aria-describedby='emailHelp' value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
              <div className='Enroll-formGroup'>
                <label htmlFor='email'>Contact Email Address</label>
                <input name='email' type='text' className='form-control' id='email' aria-describedby='emailHelp' value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='Enroll-formGroup'>
                <label htmlFor='experience'>Experience with piano</label>
                <select name='experience' id='experience' className='form-control' aria-describedby='emailHelp' onChange={(e) => setExperience(e.target.value)} value={experience}>
                  <option value='none'>None</option>
                  <option value='1to3'>1-3 years</option>
                  <option value='3plus'>3+ years</option>
                </select>
              </div>
              {
                experience !== 'none' && (
                  <>
                    <div className='Enroll-formGroup'>
                      <label htmlFor='level'>What level book are they in?</label>
                      <input name='level' type='text' className='form-control' id='level' aria-describedby='emailHelp' value={level} onChange={(e) => setLevel(e.target.value)} />
                    </div>
                    <div className='Enroll-formGroup'>
                      <label htmlFor='lastlesson'>When was the last time they had a piano lession?</label>
                      <input name='lastlesson' type='text' className='form-control' id='lastlesson' aria-describedby='emailHelp' value={lastlesson} onChange={(e) => setLastLesson(e.target.value)} />
                    </div>
                  </>
                )
              }
              <div className='Enroll-formGroup'>
                <label htmlFor='address'>Street Address and City</label>
                <input name='address' type='text' className='form-control' id='address' aria-describedby='emailHelp' value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>
            </>
          )
        }
        <div className='Enroll-formGroup'>
          <label htmlFor='day1'>Best day/time slot</label>
          <select name='day1' id='day1' className='form-control' aria-describedby='emailHelp' onChange={(e) => setDay1(e.target.value)} value={day1}>
            <option value='monday'>Monday</option>
            <option value='tuesday'>Tuesday</option>
            <option value='wednesday'>Wednesday</option>
            <option value='thursday'>Thursday</option>
            <option value='friday'>Friday</option>
          </select>
          <select name='time1' id='time1' className='form-control' aria-describedby='emailHelp' onChange={(e) => setTime1(e.target.value)} value={time1}>
            <option value='10am'>10am</option>
            <option value='10:30am'>10:30am</option>
            <option value='11am'>11am</option>
            <option value='11:30am'>11:30am</option>
            <option value='12pm'>12pm</option>
            <option value='12:30pm'>12:30pm</option>
            <option value='1pm'>1pm</option>
            <option value='1:30pm'>1:30pm</option>
            <option value='2pm'>2pm</option>
            <option value='2:30pm'>2:30pm</option>
            <option value='3pm'>3pm</option>
            <option value='3:30pm'>3:30pm</option>
            <option value='4pm'>4pm</option>
            <option value='4:30pm'>4:30pm</option>
            <option value='5pm'>5pm</option>
            <option value='5:30pm'>5:30pm</option>
            <option value='6pm'>6pm</option>
            <option value='6:30pm'>6:30pm</option>
            <option value='7pm'>7pm</option>
          </select>
        </div>
        <div className='Enroll-formGroup'>
          <label htmlFor='day2'>Second best day/time slot</label>
          <select name='day2' id='day2' className='form-control' aria-describedby='emailHelp' onChange={(e) => setDay2(e.target.value)} value={day2}>
            <option value='monday'>Monday</option>
            <option value='tuesday'>Tuesday</option>
            <option value='wednesday'>Wednesday</option>
            <option value='thursday'>Thursday</option>
            <option value='friday'>Friday</option>
          </select>
          <select name='time2' id='time2' className='form-control' aria-describedby='emailHelp' onChange={(e) => setTime2(e.target.value)} value={time2}>
            <option value='10am'>10am</option>
            <option value='10:30am'>10:30am</option>
            <option value='11am'>11am</option>
            <option value='11:30am'>11:30am</option>
            <option value='12pm'>12pm</option>
            <option value='12:30pm'>12:30pm</option>
            <option value='1pm'>1pm</option>
            <option value='1:30pm'>1:30pm</option>
            <option value='2pm'>2pm</option>
            <option value='2:30pm'>2:30pm</option>
            <option value='3pm'>3pm</option>
            <option value='3:30pm'>3:30pm</option>
            <option value='4pm'>4pm</option>
            <option value='4:30pm'>4:30pm</option>
            <option value='5pm'>5pm</option>
            <option value='5:30pm'>5:30pm</option>
            <option value='6pm'>6pm</option>
            <option value='6:30pm'>6:30pm</option>
            <option value='7pm'>7pm</option>
          </select>
        </div>
        <div className='Enroll-formGroup'>
          <label htmlFor='day3'>Third best day/time slot</label>
          <select name='day3' id='day3' className='form-control' aria-describedby='emailHelp' onChange={(e) => setDay3(e.target.value)} value={day3}>
            <option value='monday'>Monday</option>
            <option value='tuesday'>Tuesday</option>
            <option value='wednesday'>Wednesday</option>
            <option value='thursday'>Thursday</option>
            <option value='friday'>Friday</option>
          </select>
          <select name='time3' id='time3' className='form-control' aria-describedby='emailHelp' onChange={(e) => setTime3(e.target.value)} value={time3}>
            <option value='10am'>10am</option>
            <option value='10:30am'>10:30am</option>
            <option value='11am'>11am</option>
            <option value='11:30am'>11:30am</option>
            <option value='12pm'>12pm</option>
            <option value='12:30pm'>12:30pm</option>
            <option value='1pm'>1pm</option>
            <option value='1:30pm'>1:30pm</option>
            <option value='2pm'>2pm</option>
            <option value='2:30pm'>2:30pm</option>
            <option value='3pm'>3pm</option>
            <option value='3:30pm'>3:30pm</option>
            <option value='4pm'>4pm</option>
            <option value='4:30pm'>4:30pm</option>
            <option value='5pm'>5pm</option>
            <option value='5:30pm'>5:30pm</option>
            <option value='6pm'>6pm</option>
            <option value='6:30pm'>6:30pm</option>
            <option value='7pm'>7pm</option>
          </select>
        </div>
        {
          !reEnrollment && (
            <>
              <div className='Enroll-formGroup'>
                <label htmlFor='extra'>Anything else you would like me to know about student or family</label>
                <textarea name='extra' rows='5' className='form-control' id='extra' aria-describedby='emailHelp' value={extra} onChange={(e) => setExtra(e.target.value)} />
              </div>
              <div className='Enroll-formGroup'>
                <label htmlFor='message'>What goals would you like us to help your student achieve? (overcome a fear of performing, becoming the next Mozart, establish consistent practice, explore a fun and creative outlet, etc)</label>
                <textarea name='goals' rows='5' className='form-control' id='goals' aria-describedby='emailHelp' value={goals} onChange={(e) => setGoals(e.target.value)} />
              </div>
            </>
          )
        }
        <button type='submit' className='btn btn-primary'>Submit</button>
      </form>
    </div>
  );
};

export default Enroll;
