import React from "react";
import './index.css';

const Tuition = () => {
  return (
    <div id='tuition' className='Tuition-root'>
      <h1 className='Tuition-title'>Tuition</h1>
      <div className='Tuition-desc'>
        <p>
          We know that life can be hectic at times with changes in schedules throughout the year, and new activities starting in different seasons! This is why we base our
          tuition on 3 semesters throughout the year (Spring, Summer and Fall). We will have enrollment periods before each new semester to change around scheduling if needed
          to accommodate new activities outside of piano.
        </p>
        <p>
          Tuition will be prorated per semester; <b>your tuition reserves a specific day and time slot</b>. Tuition rates are determined based on a calendar year broken into
          3 semesters and 44 weekly private lessons.
        </p>
        <p>
          Breaks include 1 week Spring break, 1 week Fall break, 1 week Thanksgiving break, 2 weeks Winter break, 1 week Summer break. There are 2 "flex weeks" where the
          teacher can take off for any reason, if the teacher does not end up using all "flex weeks" you will receive those lessons free of charge.
        </p>
        <p>
          Payments are due by your first lesson of the month, if paying monthly. If a single installment payment is made it is due the starting date of that semester. If
          a student begins lessons in the middle of the month, the remaining lessons in the current month are prorated. <b>New students have a registration fee of $20, This
          goes towards student supplies (i.e., binder notebook, etc.)</b>
        </p>
        <p>
          <b>If you have 3 or more students in your household, we offer a discount.</b>
        </p>
      </div>
      <p className='Tuition-subtitle'>Spring Semester - 19 weeks</p>
      <table>
        <tr>
          <th>Weekly Lessons</th>
          <th>30-Minute</th>
          <th>45-Minute</th>
          <th>60-Minute</th>
        </tr>
        <tr>
          <th>Single Installment</th>
          <td>$600</td>
          <td>$855</td>
          <td>$1,110</td>
        </tr>
        <tr>
          <th>Monthly Installment</th>
          <td>$135</td>
          <td>$190</td>
          <td>$250</td>
        </tr>
      </table>
      <p className='Tuition-subtitle'>Summer Semester - 11 weeks</p>
      <table>
        <tr>
          <th>Weekly Lessons</th>
          <th>30-Minute</th>
          <th>45-Minute</th>
          <th>60-Minute</th>
        </tr>
        <tr>
          <th>Single Installment</th>
          <td>$345</td>
          <td>$495</td>
          <td>$645</td>
        </tr>
        <tr>
          <th>Monthly Installment</th>
          <td>$128</td>
          <td>$185</td>
          <td>$240</td>
        </tr>
      </table>
      <p className='Tuition-subtitle'>Fall Semester - 16 weeks</p>
      <table>
        <tr>
          <th>Weekly Lessons</th>
          <th>30-Minute</th>
          <th>45-Minute</th>
          <th>60-Minute</th>
        </tr>
        <tr>
          <th>Single Installment</th>
          <td>$505</td>
          <td>$720</td>
          <td>$940</td>
        </tr>
        <tr>
          <th>Monthly Installment</th>
          <td>$140</td>
          <td>$200</td>
          <td>$260</td>
        </tr>
      </table>
    </div>
  );
};

export default Tuition;
